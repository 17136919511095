import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { useCompaniesList } from '../../../../hooks/useCompaniesList';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { FilterToolbarProjectsIcon } from '../../../../icons';
import * as projectActions from '../../../../redux/slices/projectSlice';
import { subContractService, userService } from '../../../../services';
import {
  getSessionTokenData,
  getSignedUser
} from '../../../../utils/userUtils';
import DropdownSystemV2 from '../../../DesignSystem/DropdownSystemV2/DropdownSystemV2';
import NotificationSystemV2 from '../../../DesignSystem/NotificationSystemV2';
import PopoverCompanySystem from '../../../DesignSystem/PopoverCompanySystem';
import Search from '../../../Search/Search';
import { TYPE_VIEW_TIMELINE, TYPE_VIEWS } from './constants';
import SortColumnsGantt from './SortColumnsGantt';
import ToolbarRight from './ToolbarRight';

function FiltersHeader({
  t,
  typeView,
  setTypeView,
  setFilterText,
  zoomLevel,
  filterCompany,
  setFilterCompany,
  currentColumnSort,
  setCurrentColumnSort,
  isDesc,
  setIsDesc,
  applySorting
}) {
  const user = getSignedUser();

  const dispatch = useDispatch();
  const companyPopoverRef = useRef();
  const { companies, setCompanies } = useCompaniesList(
    subContractService,
    NotificationSystemV2,
    user?.companyId
  );

  const permissions = useConfigurationPermissions();
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);

  const renderButtonCreateProject = () => {
    const userStorage = getSessionTokenData();
    if (!userStorage) return null;

    if (
      userStorage.role === 'superadmin' ||
      (userStorage.role === 'admin' &&
        stateCompany?.currentCompany?.contract_type === 'enterprise')
    ) {
      return (
        <button
          disabled={permissions.project !== 'AC'}
          onClick={() =>
            dispatch(
              projectActions.setProps({
                ...projectState.props,
                openAddProject: true
              })
            )
          }>
          <PlusOutlined className="create-project__icon" />
          <span>{t('project_header.add_project')}</span>
        </button>
      );
    }

    return null;
  };
  const sessionTokenData = getSessionTokenData();
  const items = [
    {
      key: TYPE_VIEWS.CARD.key,
      label: t(`project_header.${TYPE_VIEWS.CARD.label}`),
      icon: TYPE_VIEWS.CARD.icon,
      onClick: () => {
        setTypeView('CARD');
        userService.setUserProjectsView(user.id, 'CARD');
        trackingEvent(
          'timeline_view_selected',
          {
            user_id: user.id,
            view_type: 'CARD',
            role: sessionTokenData.role
          },
          AMPLITUDE_SERVICE
        );
      },
      isDisabled: false,
      isGroup: false,
      isDivider: false,
      isActive: TYPE_VIEWS[typeView]?.key === TYPE_VIEWS.CARD.key,
      subitems: []
    },
    {
      key: TYPE_VIEWS.TIMELINE.key,
      label: t(`project_header.${TYPE_VIEWS.TIMELINE.label}`),
      icon: TYPE_VIEWS.TIMELINE.icon,
      onClick: () => {
        setTypeView('TIMELINE');
        userService.setUserProjectsView(user.id, 'TIMELINE');
        trackingEvent(
          'timeline_view_selected',
          {
            user_id: user.id,
            view_type: 'TIMELINE',
            role: sessionTokenData.role
          },
          AMPLITUDE_SERVICE
        );
      },
      isDisabled: false,
      isGroup: false,
      isDivider: false,
      isActive: TYPE_VIEWS[typeView]?.key === TYPE_VIEWS.TIMELINE.key,
      subitems: []
    }
  ];

  const updateFilterText = (value) => {
    setFilterText(value);
  };

  const handleClearFilters = () => {
    trackingEvent(
      'filter_cleared',
      {
        cleared_filters_companies: [filterCompany?.value]
      },
      AMPLITUDE_SERVICE
    );

    setFilterCompany();

    setCompanies((prev) =>
      prev.map((company) => ({ ...company, checked: false }))
    );
  };

  return (
    <div id="toolbar_filters_header" className="toolbar-filters-header">
      <div className="filters-header__left">
        <Search t={t} callback={updateFilterText} typeView={typeView} />

        <div className="left__dropdown">
          {DropdownSystemV2({
            theme: 'light',
            version: 'v2',
            trigger: ['click'],
            height: 32,
            label: t(`project_header.${TYPE_VIEWS[typeView].label}`),
            icon: TYPE_VIEWS[typeView].icon,
            items
          })}
        </div>

        {typeView === TYPE_VIEW_TIMELINE && (
          <div className="left_buttons">
            <PopoverCompanySystem
              itemReference={companyPopoverRef}
              trigger={'click'}
              placement={'bottom'}
              items={companies}
              onItemCheckedFunction={(item) => {
                if (typeof item === 'undefined') {
                  setFilterCompany(undefined);
                  return;
                }

                const companyProperties = ['id', 'name', 'color'];
                const isItemValid = typeof item === 'object';
                const isItemComplete = !companyProperties.some(
                  (key) => typeof item[key] === 'undefined'
                );

                const isValidCompany = isItemValid && isItemComplete;

                if (!isValidCompany) {
                  setFilterCompany(undefined);
                  return;
                }

                const company = isValidCompany
                  ? companyProperties.reduce(
                      (res, key) => ({ ...res, [key]: item[key] }),
                      {}
                    )
                  : undefined;

                setFilterCompany(company);
              }}>
              <div className="left_buttons__button">
                {filterCompany && <div className="button__count">1</div>}
                <div className="button__icon">
                  <FilterToolbarProjectsIcon />
                </div>
              </div>
            </PopoverCompanySystem>
            <SortColumnsGantt
              currentColumnSort={currentColumnSort}
              setCurrentColumnSort={setCurrentColumnSort}
              isDesc={isDesc}
              setIsDesc={setIsDesc}
              applySorting={applySorting}
            />
            {filterCompany && (
              <button
                className="left_buttons__clear"
                onClick={handleClearFilters}>
                {t('project_header.clear_all_filters_button')}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="filters-header__right">
        {typeView === TYPE_VIEW_TIMELINE && (
          <div className="right__toolbar">
            <ToolbarRight t={t} zoomLevel={zoomLevel} />
          </div>
        )}
        <div className="right__create-project">
          {renderButtonCreateProject()}
        </div>
      </div>
    </div>
  );
}
FiltersHeader.propTypes = {
  t: PropTypes.func.isRequired,
  typeView: PropTypes.string.isRequired,
  setTypeView: PropTypes.func.isRequired,
  setFilterText: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number,
  filterCompany: PropTypes.string,
  setFilterCompany: PropTypes.func.isRequired,
  currentColumnSort: PropTypes.string.isRequired,
  setCurrentColumnSort: PropTypes.func.isRequired,
  isDesc: PropTypes.bool.isRequired,
  setIsDesc: PropTypes.func.isRequired,
  applySorting: PropTypes.func.isRequired
};

export default withTranslation()(FiltersHeader);
