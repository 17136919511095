import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSortColumnsGantt } from '../hooks/useSortColumnsGantt';
import PopoverUniselectSystem from '../../../../DesignSystem/PopoverUniselectSystem';
import TooltipSystemV2 from '../../../../DesignSystem/TooltipSystemV2';
import {
  ArrowDropdownIcon,
  ArrowAscIcon,
  ArrowDescIcon
} from '../../../../../icons';
import { Colors } from '../../../../../constants/colors.constants';

const SortColumnsGantt = ({
  currentColumnSort,
  setCurrentColumnSort,
  isDesc,
  setIsDesc,
  applySorting
}) => {
  const { t } = useTranslation();

  const { GRAY_1 } = Colors;

  const { items, handleChangeDescSort, popoverSortRef } = useSortColumnsGantt(
    currentColumnSort,
    setCurrentColumnSort,
    isDesc,
    setIsDesc,
    applySorting
  );

  return (
    <div className="sort-columns-gantt">
      <PopoverUniselectSystem
        itemReference={popoverSortRef}
        trigger="click"
        items={items}
        countItemsShow={8}
        placement="bottom"
        withSearch={false}>
        <div className="sort-columns-gantt__popover">
          <span>
            {t(`project_header.sort_columns.column_${currentColumnSort}`)}
          </span>
          <ArrowDropdownIcon />
        </div>
      </PopoverUniselectSystem>
      <TooltipSystemV2
        title={
          isDesc
            ? t('project_header.sort_columns.tooltip_text_descendant')
            : t('project_header.sort_columns.tooltip_text_ascending')
        }>
        <button
          className="sort-columns-gantt__button"
          onClick={handleChangeDescSort}>
          {isDesc ? (
            <ArrowDescIcon color={GRAY_1} />
          ) : (
            <ArrowAscIcon color={GRAY_1} />
          )}
        </button>
      </TooltipSystemV2>
    </div>
  );
};

export default SortColumnsGantt;
