import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';

const ganttName = 'ganttScheduleOfSchedule';

export const useSortColumnsGantt = (
  currentColumnSort,
  setCurrentColumnSort,
  isDesc,
  setIsDesc,
  applySorting
) => {
  const { t } = useTranslation();

  const popoverSortRef = useRef();

  const [ganttColumns, setGanttColumns] = useState();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window[ganttName]) {
        setGanttColumns(window[ganttName]?.config?.columns || []);
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!ganttColumns) {
      return;
    }

    const currentGroup = [];

    ganttColumns.forEach((column) => {
      if (column.name === 'actions') {
        return;
      }

      currentGroup.push({
        key: column.name,
        name: t(`project_header.sort_columns.column_${column.name}`),
        active: column.name === currentColumnSort,
        icon: null,
        disabled: false,
        onClick: () => handleChangeColumnSort(column.name),
        subitems: []
      });
    });

    setItems([
      {
        key: 1,
        name: t('project_header.sort_columns.popover_title'),
        active: false,
        icon: null,
        disabled: false,
        onClick: null,
        subitems: [],
        group: currentGroup
      }
    ]);
  }, [ganttColumns]);

  useEffect(() => {
    setItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        group: item.group.map((groupItem) => ({
          ...groupItem,
          active: groupItem.key === currentColumnSort
        }))
      }))
    );
  }, [currentColumnSort]);

  const handleChangeColumnSort = (key) => {
    if (!window[ganttName]) return;

    handleClosePopover();

    const resetIsDesc = key !== currentColumnSort;
    setCurrentColumnSort(key);
    setIsDesc(resetIsDesc ? false : isDesc);

    handleTrackingSort(key, isDesc);

    applySorting(window[ganttName], key, resetIsDesc ? false : isDesc);
  };

  const handleChangeDescSort = () => {
    if (!window[ganttName]) return;

    const newDescState = !isDesc;
    setIsDesc(newDescState);

    handleTrackingSort(currentColumnSort, newDescState);

    applySorting(window[ganttName], currentColumnSort, newDescState);
  };

  const handleTrackingSort = (column, sort) => {
    trackingEvent(
      'home_timeline_sort_clicked',
      {
        ...getBasicAmplitudEventProperties(),
        column_name: t(`project_header.sort_columns.column_${column}`),
        order_type: sort ? 'desc' : 'asc'
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleClosePopover = () => {
    if (popoverSortRef.current) {
      popoverSortRef.current.tooltip.state.visible = false;
    }
  };

  return {
    items,
    handleChangeDescSort,
    popoverSortRef
  };
};
