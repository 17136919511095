/** React stuff */
import React from 'react';
import PropTypes from 'prop-types';

/** import library for render progessive images with lazy loading */
import ProgressiveImage from 'react-progressive-image';

/** import library for animations effects */
import { Animated } from 'react-animated-css';

/** import common functions from utils */
import { getClassForCards } from '../../../../utils';

/** import icons from antd framework */
import { CalendarOutlined } from '@ant-design/icons';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';

/** Import elements from library Antd */
import { Col, Tooltip } from 'antd';

import NumberFormat from 'react-number-format';

/** Moment for dates using */
import moment from 'moment';

import { withTranslation } from 'react-i18next';

/** Components */
import ActionsMenu from '../../ActionsMenu'; /** menu project (edit copy delete) */

/** PNG to put on users without image */
import noImageProject from '../../../../assets/img/noimage-s.png';
import Colors from '../../../../assets/styles/variables.scss';
import {
  HOME_CARD_VIEW,
  SETTINGS_USER_VIEW
} from '../../../../hooks/useSelectProject';
import { DollarCoinIcon, RulerIcon } from '../../../../icons';

const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY'];
/** test id */
export const testIds = {
  RENDER_ITEM_CARD_CONTAINER: 'RENDER_ITEM_CARD_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_ITEM_CARD_CONTAINER}>{children}</span>
);

TestWrapped.propTypes = {
  children: PropTypes.node.isRequired
};

function ItemCard(props) {
  const { DARK_MINT } = Colors;
  /** props */
  const {
    project,
    showActionsMenu = true,
    t,
    setFormProjectVisible,
    setFormDeleteVisible,
    caller = 'projects',
    onClickProject,
    analytics = {}
  } = props;

  /** consts */
  const subtitleProject = project.address || t('without_direction');
  const navigatorLang = navigator.language || navigator.userLanguage;

  /** get windows dimensions from hook */
  const { width } = useWindowDimensions();
  const classForCards = getClassForCards(width);

  /** render */
  return (
    <Col align="center" className={classForCards}>
      <Animated animationIn="fadeIn" animationInDuration={1000}>
        <TestWrapped>
          <div
            className="cardProject"
            onClick={() =>
              onClickProject(
                project,
                caller === 'settings' ? SETTINGS_USER_VIEW : HOME_CARD_VIEW,
                analytics
              )
            }>
            <div
              style={{
                minHeight: caller === 'projects' ? 152 : null
              }}>
              <div className="imgPry">
                {
                  <ProgressiveImage
                    delay={500}
                    src={project.image ? project.image : noImageProject}
                    placeholder={noImageProject}>
                    {(src, loading) => (
                      <img
                        src={src}
                        className={
                          loading ? 'imgProject img-blur-project' : 'imgProject'
                        }
                      />
                    )}
                  </ProgressiveImage>
                }
                {showActionsMenu && (
                  <ActionsMenu
                    {...props}
                    project={project}
                    setFormProjectVisible={setFormProjectVisible}
                    setFormDeleteVisible={setFormDeleteVisible}
                  />
                )}
              </div>
              <div className="dateProject">
                <CalendarOutlined style={{ marginRight: 5 }} />
                {moment(project.createdAt).format(
                  t('lang') === 'es' ? dateFormatList[0] : dateFormatList[1]
                )}
              </div>
            </div>
            <div className="titleProject">
              <Tooltip title={project.name} placement="topLeft">
                {project.name}
              </Tooltip>
            </div>
            <div className="subTitleProject">
              <Tooltip title={subtitleProject} placement="topLeft">
                {subtitleProject}
              </Tooltip>
            </div>

            <div className="divider" />

            <div className="propsProject">
              <div className="pbudget">
                {/* Budget */}
                <Tooltip title={t('project_list.project_amount')}>
                  <DollarCoinIcon color={DARK_MINT} />
                  <span className="propertyText">
                    {project.currency}{' '}
                    <NumberFormat
                      value={project.budget}
                      displayType="text"
                      thousandSeparator={
                        navigatorLang.includes('en') ? ',' : '.'
                      }
                      decimalSeparator={
                        navigatorLang.includes('en') ? '.' : ','
                      }
                    />
                  </span>
                </Tooltip>
              </div>
              {/* SIZE */}
              <div className="psize">
                <Tooltip title={t('project_list.project_size')}>
                  <RulerIcon color={DARK_MINT} />
                  <span className="propertyText">
                    <NumberFormat
                      value={project.size}
                      displayType="text"
                      thousandSeparator={
                        navigatorLang.includes('en') ? ',' : '.'
                      }
                      decimalSeparator={
                        navigatorLang.includes('en') ? '.' : ','
                      }
                    />{' '}
                    {project.sizetype}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </TestWrapped>
      </Animated>
    </Col>
  );
}
ItemCard.propTypes = {
  project: PropTypes.object.isRequired,
  showActionsMenu: PropTypes.bool,
  t: PropTypes.func.isRequired,
  setFormProjectVisible: PropTypes.func,
  setFormDeleteVisible: PropTypes.func,
  caller: PropTypes.string,
  onClickProject: PropTypes.func.isRequired,
  analytics: PropTypes.object
};

export default withTranslation()(ItemCard);
